import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { GridColumn } from "@progress/kendo-react-grid";
import { makeStyles, Typography } from "@material-ui/core";
import Flex from "components/flex/Flex";
import Input from "components/input/Input";
import Button from "components/button/Button";
import DataTable from "components/table/Table";
import GridRow from "components/grid-row/GridRow";
import LabelTabs from "components/label-tabs/LabelTabs";
import GridTable, { centerCell } from "components/gird-table/GridTable";
import AdminConfirmButton from "components/admin-confirm-button/AdminConfirmButton";
import AssetStatus from "./AssetStatus";

import * as APIS from "libs/apis";
import { formatTime } from "libs/utils";
import { formatPhone, objToQueryStr } from "services/utils";
import { actionError, actionOpen } from "redux/action/ActionActions";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";

const MAP_COLUMN_TO_LABEL = {
  createdAt: "가입일",
  name: "이름",
  sns: "가입방법",
  ageGender: "나이 / 성별 / 생년월일",
  account: "로그인 ID",
  phone: "휴대폰 / 이메일",
  virtualAccountAccountNumber: "가상계좌",
  bank: "입출금계좌",
  email: "E-mail",
  fullAddress: "주소",
  recommendShopName: "추천매장",
  status: "계정상태",
  usingShopName: "이용대리점",
};

const INIT_PAGE = {
  page: 0,
  size: 30,
  totalElements: 0,
};

export default function MemberDetail({ match }) {
  const matchId = match?.params?.id;
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const { tab } = state;
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;
  const userId = state.id || matchId;
  const [isEdit, setIsEdit] = useState(false);
  const [canReSignupDate, setCanReSignupDate] = useState("");
  const [member, setMember] = useState({});
  // 추천매장(추천대리점)
  const [shops, setShops] = useState([]);
  // 이용대리점
  const [usingShops, setUsingShops] = useState([]);
  const [banks, setBanks] = useState([]);
  const [bankCode, setBankCode] = useState("");
  const [bankNumber, setBankNumber] = useState("");
  const [depositBankCode, setDepositBankCode] = useState("");
  const [depositBankNumber, setDepositBankNumber] = useState("");
  const [modify, setModify] = useState({
    userStatus: "",
    recommendShopId: "",
    usingShopId: "",
  });
  const [userStatusHistory, setUserStatusHistory] = useState([]);
  const [userLoginHistory, setUserLoginHistory] = useState([]);
  const [page, setPage] = useState({ ...INIT_PAGE });
  const [shopStatus, setShopStatus] = useState({
    recommend: true,
    using: true,
  });
  const [usingShopStatus, setUsingShopStatus] = useState("");

  useEffect(() => {
    history.replace(history.location.pathname, {
      ...history.location.state,
      tab: "detail",
    });
  }, []);

  useEffect(() => {
    switch (tab) {
      case "detail":
        userInit();
        break;
      case "history":
        getUserStatusHistory();
        break;
      case "loginHistory":
        getUserLoginHistory();
        break;
      default:
    }
  }, [tab]);

  const userInit = () => {
    let memberData, shopData, usingShopData;
    dispatch(loadingStart);
    APIS.getRecommendShops().then(({ data: { success, data } }) => {
      if (success) {
        setShops(data);
        setUsingShops(data);
        shopData = data;
        usingShopData = data;
      }
    });
    const getUserDetail = APIS.searchUserDetail(userId).then(
      ({ data: { success, data = {}, message } }) => {
        if (success) {
          const {
            age,
            gender,
            loginId,
            postCode,
            address,
            addressDetail,
            status,
            recommendShopId,
            usingShopId,
            canReSignupDate,
            birthday,
            registerPath, // 가입 경로
            hectoSimpleBankCode, // 헥토 간편 은행 코드
            hectoSimpleBankAccountNumber, // 헥토 간편 계좌번호
            isToss, // 토스 퀵계좌 가입여부
            gaInstance,
          } = data;
          setMember({
            ...data,
            registerPath: registerPath || "APP",
            ageGender: `${age === 0 ? "-" : age} / ${
              gender === 9 ? "-" : gender ? "남자" : "여자"
            } / ${birthday}`,
            account: loginId,
            fullAddress: `(${postCode}) ${address} ${addressDetail}`,
          });
          memberData = data;
          setModify((modify) => ({
            ...modify,
            recommendShopId: recommendShopId,
            usingShopId: usingShopId,
            userStatus: status,
          }));
          setCanReSignupDate(canReSignupDate);
        } else {
          dispatch(
            actionOpen(
              message,
              () => {
                history.goBack();
              },
              null,
              null,
              true
            )
          );
        }
      }
    );
    APIS.getBankList().then(({ data: { success, data, message } }) => {
      if (success) {
        setBanks(data);
      } else {
        dispatch(actionError(message));
      }
    });

    Promise.all([getUserDetail]).finally(() => {
      dispatch(loadingEnd);
      const recommendShop = shopData?.find(
        (x) => x.shopId === memberData.recommendShopId
      );
      const usingShop = shopData?.find(
        (x) => x.shopId === memberData.usingShopId
      );
      setShopStatus({
        recommend: recommendShop?.shopStatus,
        using: usingShop?.shopStatus,
      });
      setBankCode("");
      setBankNumber("");
      setDepositBankCode("");
      setDepositBankNumber("");
    });
  };

  const getUserStatusHistory = () => {
    dispatch(loadingStart);
    APIS.searchUserStatusHistory(userId)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          const replaceData = data.map((item) => {
            return {
              ...item,
              logType: item.logType
                ?.replace("RECOMMEND_SHOP", "추천매장")
                ?.replace("USER_STATUS", "계정상태")
                ?.replace("USING_SHOP", "이용대리점"),
              beforeStatus: item.beforeStatus
                ?.replace("ACTIVE", "활성")
                ?.replace("LEAVE", "탈퇴")
                ?.replace("LOCK", "사용정지")
                ?.replace("DORMANT", "휴면")
                ?.replace("INIT", "본인인증 전"),
              currentStatus: item.currentStatus
                ?.replace("ACTIVE", "활성")
                ?.replace("LEAVE", "탈퇴")
                ?.replace("LOCK", "사용정지")
                ?.replace("DORMANT", "휴면")
                ?.replace("INIT", "본인인증 전"),
            };
          });
          setUserStatusHistory(replaceData);
        } else {
          dispatch(
            actionOpen(
              message,
              () => {
                history.goBack();
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const getUserLoginHistory = (pageData) => {
    dispatch(loadingStart);
    const parsePage = pageData
      ? pageData
      : {
          page: page.page,
          size: page.size,
        };
    const queryString = objToQueryStr({ ...parsePage });
    APIS.searchUserLoginHistory(userId, queryString)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          const { content, totalElements, number, size } = data;
          setUserLoginHistory(content || []);
          setPage({
            ...page,
            totalElements,
            page: number * size,
            size: size,
          });
        } else {
          dispatch(
            actionOpen(
              message,
              () => {
                history.goBack();
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const onPageChange = (event) => {
    const { page: pageData } = event;
    const { skip, take } = pageData;
    getUserLoginHistory({ page: skip / take, size: take });
  };

  const getColumnData = (key, option = {}) => {
    return {
      label: MAP_COLUMN_TO_LABEL[key],
      value: member[key],
      ...option,
    };
  };

  const handleChangeModify = (key) => (value) => {
    setModify((modify) => ({ ...modify, [key]: value }));
  };

  const onClickEditReSignupDate = (adminPassword) => {
    if (!adminPassword) {
      dispatch(actionError("비밀번호를 입력하여 주세요."));
    } else {
      const data = { canReSignupDate: canReSignupDate };
      data["adminPassword"] = adminPassword;
      dispatch(loadingStart);
      APIS.modifyCanReSignupDate(userId, data)
        .then(({ data: { success, message } }) => {
          if (!success) {
            dispatch(
              actionOpen(
                message,
                () => {
                  userInit();
                },
                null,
                null,
                true
              )
            );
          } else {
            dispatch(
              actionOpen(
                "수정이 완료되었습니다.",
                () => {
                  userInit();
                  setIsEdit(false);
                },
                null,
                null,
                true
              )
            );
          }
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  const deleteAccount = (adminPassword, selectState) => {
    dispatch(loadingStart);
    const data = { adminPassword: adminPassword };
    APIS.postAccountRelease(userId, selectState, data)
      .then(({ data: { success, message, data } }) => {
        if (success) {
          dispatch(
            actionOpen(
              "삭제 처리되었습니다.",
              () => {
                userInit();
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const onClickSave2 = (adminPassword) => {
    if (member?.status === "LEAVE") {
      dispatch(
        actionError("계정상태가 탈퇴인 회원의 정보는 수정할 수 없습니다.")
      );
    } else {
      const param = {
        ...modify,
        bankCode: bankCode,
        bankNumber: bankNumber,
        depositBankCode: depositBankCode,
        depositBankNumber: depositBankNumber,
      };

      if (modify.recommendShopId === "" || modify.recommendShopId === null) {
        delete param["recommendShopId"];
      }
      if (bankCode === "" || bankCode === null || member.bankCode) {
        delete param["bankCode"];
      }
      if (
        bankNumber === "" ||
        bankNumber === null ||
        member.bankAccountNumber
      ) {
        delete param["bankNumber"];
      }
      if (
        depositBankCode === "" ||
        depositBankCode === null ||
        member.depositBankCode
      ) {
        delete param["depositBankCode"];
      }
      if (
        depositBankNumber === "" ||
        depositBankNumber === null ||
        member.depositBankAccountNumber
      ) {
        delete param["depositBankNumber"];
      }
      param["adminPassword"] = adminPassword;
      dispatch(loadingStart);
      APIS.modifyUserDetail(userId, param)
        .then(({ data: { success, message, data } }) => {
          if (!success) {
            if (data) {
              dispatch(
                actionOpen(
                  `${message}
                  ${data}`,
                  () => {
                    APIS.postBankAccount(userId, param).then(
                      ({ data: { success, message } }) => {
                        if (!success) {
                          dispatch(
                            actionOpen(
                              message,
                              () => {
                                userInit();
                              },
                              null,
                              null,
                              true
                            )
                          );
                        } else {
                          setTimeout(() => {
                            dispatch(
                              actionOpen(
                                "저장이 완료되었습니다.",
                                userInit,
                                null,
                                null,
                                true
                              )
                            );
                          }, 3000);
                        }
                      }
                    );
                  },
                  null,
                  null,
                  false
                )
              );
            } else {
              dispatch(
                actionOpen(
                  message,
                  () => {
                    userInit();
                  },
                  null,
                  null,
                  true
                )
              );
            }
          } else {
            setTimeout(() => {
              dispatch(
                actionOpen("저장이 완료되었습니다.", userInit, null, null, true)
              );
            }, 3000);
          }
        })
        .finally(() => {
          setTimeout(() => {
            dispatch(loadingEnd);
          }, 3000);
        });
    }
  };

  const { userStatus, recommendShopId, usingShopId } = modify;
  return (
    <>
      <LabelTabs
        className={classes.labelTabs}
        data={[
          { label: "회원 상세정보", value: "detail" },
          { label: "회원 정보 변경 이력", value: "history" },
          { label: "회원 접속 이력", value: "loginHistory" },
        ]}
      />
      {state.tab === "loginHistory" ? (
        <>
          <GridTable
            style={{
              margin: "10px 50px 20px 50px",
              height: "100%",
              maxHeight: 750,
            }}
            name={"LoginHistory"}
            reorderable
            data={userLoginHistory}
            skip={page.page}
            take={page.size}
            total={page.totalElements}
            pageable
            onPageChange={onPageChange}
            scrollable>
            <GridColumn
              field="lastLogin"
              title="일시"
              cell={(props) => {
                const { dataItem, field } = props;
                return (
                  <td style={{ textAlign: "center" }}>
                    {dataItem[field]
                      ? formatTime(dataItem[field], "YYYY-MM-DD HH:mm:ss")
                      : "-"}
                  </td>
                );
              }}
            />
            <GridColumn field="os" title="기종" cell={centerCell} />
            <GridColumn field="osVersion" title="기종 버전" cell={centerCell} />
            <GridColumn field="appVersion" title="앱 버전" cell={centerCell} />
            <GridColumn field="ip" title="IP" cell={centerCell} />
            <GridColumn
              field="oauthType"
              title="로그인 방식"
              cell={centerCell}
            />
            <GridColumn field="socialKey" title="소셜 키" cell={centerCell} />
          </GridTable>
        </>
      ) : state.tab === "history" ? (
        <>
          <GridTable
            style={{
              margin: "10px 50px 0px 50px",
              maxHeight: 755,
            }}
            name={"UserStatusHistory"}
            reorderable
            data={userStatusHistory}
            scrollable>
            <GridColumn field="logType" title="변경구분" cell={centerCell} />
            <GridColumn
              field="createdAt"
              title="변경일시"
              cell={(props) => {
                const { dataItem, field } = props;
                return (
                  <td style={{ textAlign: "center" }}>
                    {dataItem[field]
                      ? formatTime(dataItem[field], "YYYY-MM-DD HH:mm:ss")
                      : "-"}
                  </td>
                );
              }}
            />
            <GridColumn field="userName" title="회원이름" cell={centerCell} />
            <GridColumn
              field="beforeStatus"
              title="변경 전"
              cell={centerCell}
            />
            <GridColumn
              field="currentStatus"
              title="변경 후"
              cell={centerCell}
            />
            <GridColumn
              field="managerName"
              title="관리자"
              cell={(props) => {
                const { dataItem, field } = props;
                return (
                  <td style={{ textAlign: "center" }}>
                    {dataItem[field] || "-"}
                  </td>
                );
              }}
            />
            <GridColumn
              field=""
              title="비고"
              cell={(props) => {
                const { dataItem, field } = props;
                return (
                  <td style={{ textAlign: "center" }}>
                    {dataItem[field] || "-"}
                  </td>
                );
              }}
            />
          </GridTable>
        </>
      ) : (
        <>
          {member.id && (
            <>
              {isKorda &&
                (member.status === "LEAVE" ? (
                  <Button
                    label="저장"
                    onClick={() => {
                      dispatch(
                        actionError(
                          "계정상태가 탈퇴인 회원의 정보는 수정할 수 없습니다."
                        )
                      );
                    }}
                    className={classes.infoLeaveSaveButton}
                  />
                ) : (
                  <AdminConfirmButton
                    popupMessage="변경하시겠습니까?"
                    label="저장"
                    popupLabel="회원정보 변경"
                    callback={onClickSave2}
                    rootClassName={classes.confirmInfoSaveButton}
                  />
                ))}
              <GridRow
                label="기본정보"
                data={[
                  {
                    label: "가입일 / 수정일 / 재가입일",
                    type: "render",
                    render: (
                      <Flex row>
                        {isEdit ? (
                          <Flex row>
                            <Typography>
                              {formatTime(member.createdAt || "-").replace(
                                "Invalid date",
                                " - "
                              ) +
                                " / " +
                                formatTime(member.updatedAt || "-").replace(
                                  "Invalid date",
                                  " - "
                                )}
                            </Typography>
                            <input
                              value={canReSignupDate || ""}
                              onChange={(e) => {
                                setCanReSignupDate(e.target.value);
                              }}
                              type="date"
                              style={{ marginLeft: 10, height: 30 }}
                            />
                            <AdminConfirmButton
                              popupMessage="변경하시겠습니까?"
                              label="저장"
                              popupLabel="재가입일 수정"
                              callback={onClickEditReSignupDate}
                              rootClassName={classes.confirmRoot}
                              btnClassName={classes.confirmButton}
                            />
                          </Flex>
                        ) : (
                          <Flex row>
                            <Typography>
                              {formatTime(member.createdAt || "-").replace(
                                "Invalid date",
                                " - "
                              ) +
                                " / " +
                                formatTime(member.updatedAt || "-").replace(
                                  "Invalid date",
                                  " - "
                                ) +
                                " / " +
                                formatTime(
                                  member.canReSignupDate || "-"
                                ).replace("Invalid date", " - ")}
                            </Typography>
                            {member.status === "LEAVE" ? (
                              <Button
                                label="수정"
                                className={classes.editButton}
                                onClick={() => {
                                  setIsEdit(true);
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </Flex>
                        )}
                      </Flex>
                    ),
                  },
                  // getColumnData("createdAt", { renderWidth: "65%" }),
                  getColumnData("name"),
                  getColumnData("sns"),
                  getColumnData("ageGender"),
                  getColumnData("account"),
                  {
                    label: "휴대폰 / 이메일",
                    type: "render",
                    render: (
                      <Flex row style={{ flex: 1 }}>
                        <Typography>{`${formatPhone(member.phone)} / ${
                          member.email || "-"
                        }`}</Typography>
                      </Flex>
                    ),
                  },
                  isKorda
                    ? {
                        label: "계정상태",
                        value: userStatus,
                        type: "radio",
                        data: [
                          { label: "활성", value: "ACTIVE" },
                          { label: "사용정지", value: "LOCK" },
                          { label: "휴면", value: "DORMANT" },
                          { label: "탈퇴", value: "LEAVE" },
                        ],
                        onChange: handleChangeModify("userStatus"),
                      }
                    : getColumnData("status", { renderWidth: "65%" }),
                  {
                    // 이용대리점
                    label: "이용대리점",
                    type: "render",
                    render: (
                      <Flex row style={{ alignItems: "center" }}>
                        <select
                          style={{ minWidth: 50, fontSize: 16 }}
                          value={usingShopId}
                          onChange={(e) => {
                            const selectShop = shops.find(
                              (x) => x.shopId === Number(e.target.value)
                            );
                            setShopStatus(selectShop?.shopStatus);
                            setModify({
                              ...modify,
                              usingShopId: e.target.value,
                            });
                          }}
                          disabled={!isKorda}>
                          <option value={""}>{"없음"}</option>
                          {shops.map((shop, i) => {
                            return (
                              <option key={i.toString()} value={shop.shopId}>
                                {shop.shopName}
                              </option>
                            );
                          })}
                        </select>
                        {shopStatus === "DISABLED" && (
                          <div style={{ marginLeft: 10, color: "red" }}>
                            <span>(비활성 매장입니다)</span>
                          </div>
                        )}
                      </Flex>
                    ),
                  },
                  // {
                  //   // 이용대리점
                  //   label: "이용대리점",
                  //   type: "render",
                  //   render: (
                  //     <Flex row style={{ alignItems: "center" }}>
                  //       <select
                  //         style={{ minWidth: 50, fontSize: 16 }}
                  //         value={usingShopId}
                  //         onChange={(e) => {
                  //           const selectShop = usingShops.find(
                  //             (x) => x.shopId === Number(e.target.value)
                  //           );
                  //           setUsingShopStatus(selectShop?.shopStatus);
                  //           setModify({
                  //             ...modify,
                  //             usingShopId: e.target.value,
                  //           });
                  //         }}
                  //         disabled={!isKorda}>
                  //         <option value={""}>{"없음"}</option>
                  //         {usingShops.map((shop, i) => {
                  //           return (
                  //             <option key={i.toString()} value={shop.shopId}>
                  //               {shop.shopName}
                  //             </option>
                  //           );
                  //         })}
                  //       </select>
                  //       {usingShopStatus === "DISABLED" && (
                  //         <div style={{ marginLeft: 10, color: "red" }}>
                  //           <span>(비활성 매장입니다)</span>
                  //         </div>
                  //       )}
                  //     </Flex>
                  //   ),
                  // },
                  {
                    // 추천매장 -> 추천대리점
                    label: "추천대리점",
                    type: "render",
                    render: (
                      <Flex row style={{ alignItems: "center" }}>
                        <select
                          style={{ minWidth: 50, fontSize: 16 }}
                          value={recommendShopId}
                          onChange={(e) => {
                            const selectShop = shops.find(
                              (x) => x.shopId === Number(e.target.value)
                            );
                            setShopStatus(selectShop?.shopStatus);
                            setModify({
                              ...modify,
                              recommendShopId: e.target.value,
                            });
                          }}
                          disabled={!isKorda}>
                          <option value={""}>{"없음"}</option>
                          {shops.map((shop, i) => {
                            return (
                              <option key={i.toString()} value={shop.shopId}>
                                {shop.shopName}
                              </option>
                            );
                          })}
                        </select>
                        {shopStatus === "DISABLED" && (
                          <div style={{ marginLeft: 10, color: "red" }}>
                            <span>(비활성 매장입니다)</span>
                          </div>
                        )}
                      </Flex>
                    ),
                  },
                  {
                    label: "가입경로",
                    value: member.registerPath,
                  },
                  {
                    label: "Ga instance",
                    value: member.gaInstance,
                  },
                  {
                    label: "AD-ID / OS",
                    type: "render",
                    render: (
                      <Flex row style={{ flex: 1 }}>
                        <Typography>
                          {`${member.adId || "-"} / ${member.os || "-"}`}
                        </Typography>
                      </Flex>
                    ),
                  },

                  {
                    label: "출금계좌",
                    type: "render",
                    render: member.bankCode ? (
                      <Flex row style={{ flex: 1 }}>
                        <Typography>
                          {`${
                            banks.find((bank) => bank.code === member.bankCode)
                              ?.name
                          } / ${member.bankAccountNumber}`}
                        </Typography>
                        <AdminConfirmButton
                          popupMessage="변경하시겠습니까?"
                          label="삭제"
                          popupLabel="출금계좌 삭제"
                          callback={deleteAccount}
                          selectItem={"WITHDRAW"}
                          rootClassName={classes.confirmRoot}
                          btnClassName={classes.confirmButton}
                        />
                      </Flex>
                    ) : (
                      <Flex row style={{ flex: 1 }}>
                        <select
                          style={{ fontSize: 16 }}
                          value={bankCode}
                          onChange={(e) => {
                            setBankCode(e.target.value);
                          }}>
                          <option value={""}>{"없음"}</option>
                          {banks.map((bank, i) => {
                            return (
                              <option key={i.toString()} value={bank.code}>
                                {bank.name}
                              </option>
                            );
                          })}
                        </select>
                        <Input
                          className={classes.bankNumberInput}
                          value={bankNumber}
                          onChange={setBankNumber}
                        />
                      </Flex>
                    ),
                  },
                  getColumnData("virtualAccountAccountNumber"),
                  {
                    label: "입금계좌",
                    type: "render",
                    render: member.depositBankCode ? (
                      <Flex row style={{ flex: 1 }}>
                        <Typography>
                          {`${
                            member.depositBankAccountStatus === "REQUEST"
                              ? "(처리중)"
                              : ""
                          }${
                            banks.find(
                              (bank) => bank.code === member.depositBankCode
                            )?.name
                          } / ${member.depositBankAccountNumber}`}
                        </Typography>
                        <AdminConfirmButton
                          popupMessage="변경하시겠습니까?"
                          label="삭제"
                          popupLabel="입금계좌 삭제"
                          callback={deleteAccount}
                          selectItem={"DEPOSIT"}
                          rootClassName={classes.confirmRoot}
                          btnClassName={classes.confirmButton}
                        />
                      </Flex>
                    ) : (
                      <Flex row style={{ flex: 1 }}>
                        <select
                          style={{ fontSize: 16 }}
                          value={depositBankCode}
                          onChange={(e) => {
                            setDepositBankCode(e.target.value);
                          }}>
                          <option value={""}>{"없음"}</option>
                          {banks.map((bank, i) => {
                            return (
                              <option key={i.toString()} value={bank.code}>
                                {bank.name}
                              </option>
                            );
                          })}
                        </select>
                        <input
                          style={{
                            alignSelf: "stretch",
                            borderRadius: "3px",
                            border: "1px solid rgba(0,0,0,0.23)",
                            color: "black",
                            padding: "0px 12px",
                            marginLeft: 10,
                            flex: 1,
                          }}
                          value={depositBankNumber}
                          onChange={(e) => {
                            setDepositBankNumber(e.target.value);
                          }}
                        />
                      </Flex>
                    ),
                  },
                  {
                    label: "헥토 간편",
                    type: "render",
                    render: (
                      <Flex row style={{ flex: 1 }}>
                        {banks?.find(
                          (bank) => bank.code === member.hectoSimpleBankCode
                        )?.name || member.hectoSimpleBankAccountNumber ? (
                          <>
                            {banks?.find(
                              (bank) => bank.code === member.hectoSimpleBankCode
                            )?.name || ""}
                            {member.hectoSimpleBankAccountNumber &&
                              ` / ${member.hectoSimpleBankAccountNumber}`}
                            <AdminConfirmButton
                              popupMessage="변경하시겠습니까?"
                              label="삭제"
                              popupLabel="간편계좌 삭제"
                              callback={deleteAccount}
                              selectItem={"SIMPLE"}
                              rootClassName={classes.confirmRoot}
                              btnClassName={classes.confirmButton}
                            />
                          </>
                        ) : (
                          "-"
                        )}
                      </Flex>
                    ),
                  },
                  {
                    label: "토스 퀵계좌",
                    type: "render",
                    render: <Flex>{member.isToss ? "있음" : "없음"}</Flex>,
                  },
                  {
                    label: "K-Bank 계좌",
                    type: "render",
                    render: (
                      <Flex>
                        {member.kBankNumber ? (
                          <>
                            {member.kBankNumber}
                            <AdminConfirmButton
                              popupMessage="변경하시겠습니까?"
                              label="삭제"
                              popupLabel="K-Bank계좌 삭제"
                              callback={deleteAccount}
                              selectItem={"K_BANK_MALL"}
                              rootClassName={classes.confirmRoot}
                              btnClassName={classes.confirmButton}
                            />
                          </>
                        ) : (
                          "-"
                        )}
                      </Flex>
                    ),
                  },
                ]}
              />

              <DataTable
                dashboard={[
                  { label: "보유금액" },
                  { label: "금(GOLD)" },
                  { label: "은(SILVER)" },
                  { label: "매수/매도" },
                  { label: "실물인출" },
                  { label: "안심직거래" },
                ]}
                // onExcelDownload={() => {}}
                columns={[
                  { label: "구분" },
                  { label: "거래 시간" },
                  { label: "주문형태" },
                  { label: "구분" },
                  { label: "거래금액" },
                  { label: "수수료" },
                  { label: "체결량" },
                  { label: "수익률" },
                  { label: "주문자" },
                  { label: "매수자" },
                ]}
              />
              <AssetStatus userId={userId} />
            </>
          )}
        </>
      )}
    </>
  );
}

const useStyle = makeStyles({
  feeSection: { flex: 1, justifyContent: "space-between" },
  feeLabel: { alignItems: "center", justifyContent: "center" },
  feeInputWrap: {
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: 3,
    marginLeft: 10,
    marginRight: 10,
    padding: 5,
  },
  feeText: {
    marginLeft: 5,
  },
  bankNumberInput: {
    marginLeft: 10,
    flex: 1,
  },
  editButton: {
    marginLeft: 50,
    height: 30,
  },
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
    marginRight: 40,
  },
  confirmInfoSaveButton: {
    marginLeft: 0,
    margin: 0,
    alignItems: "center",
    marginRight: 50,
  },
  confirmButton: {
    minWidth: 70,
    height: 30,
    width: 40,
    marginLeft: 0,
  },
  infoLeaveSaveButton: {
    alignSelf: "end",
    marginRight: "50px",
    width: 150,
    height: 40,
  },
});
